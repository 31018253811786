<template>
  <Layout>
    <PageHeader
      :title="title"
      moda_title="Datos del operador"
      ref="pageForm"
      :items="items">
    </PageHeader>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-md-3 col-sm-9">
                <label class="d-inline-flex align-items-center w-100">
                  <span class="m-2">{{ $t("filter.from") }}: </span>
                  <vs-input v-model="filterData.startDate" type="date" />
                  <vs-input
                    class="ms-2"
                    type="time"
                    v-model="filterData.startHour"
                    v-if="filterData.startDate" />
                </label>
              </div>
              <div class="col-md-3 col-sm-9">
                <label class="d-inline-flex align-items-center w-100">
                  <span class="m-2">{{ $t("filter.until") }}: </span>
                  <vs-input v-model="filterData.endDate" type="date" />
                  <vs-input
                    class="ms-2"
                    type="time"
                    v-model="filterData.endHour"
                    v-if="filterData.endDate" />
                </label>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-12 d-flex justify-content-start">
                <label
                  class="d-inline-flex align-items-center"
                  style="width: max-content">
                  {{ $t("filter.gameType") }}:
                </label>
                <select
                  v-model="filterData.gameType"
                  name="client"
                  class="form-select form-select-sm my-2 ms-2 border-0 shadow-lg w-50"
                  placeholder="Seleccione.."
                  @change="filtrar">
                  <option disabled selected>
                    {{ $t("helpers.select") }}...
                  </option>
                  <option
                    v-for="(item, index) in gameTypes"
                    :key="index"
                    :value="item.value">
                    {{ item.name }}
                  </option>
                </select>
              </div>
              <div class="col-sm-12 col-md-4" v-if="checkAdmins()">
                <label class="d-inline-flex align-items-center w-100">
                  {{ $t("filter.client") }}:
                  <select
                    v-model="filterData.client"
                    name="client"
                    class="form-select form-select-sm m-2 border-0 shadow-lg"
                    placeholder="Seleccione..."
                    @change="getOperatorClient(filterData)">
                    <option disabled selected>
                      {{ $t("helpers.select") }}...
                    </option>
                    <option
                      v-for="(item, index) in clients"
                      :key="index"
                      :value="item._id">
                      {{ item.name }}
                    </option>
                  </select>
                </label>
              </div>
              <div
                class="col-sm-12 col-md-4"
                v-if="userRole !== 'OPERATOR' && filterData.client">
                <label class="d-inline-flex align-items-center w-100">
                  {{ $t("filter.operator") }}:
                  <select
                    v-model="filterData.operator"
                    name="client"
                    class="form-select form-select-sm m-2 border-0 shadow-lg"
                    placeholder="Seleccione.."
                    @change="
                      () => {
                        fetchWls({ operator: filterData.operator });
                        filtrar();
                      }
                    ">
                    <option disabled selected>
                      {{ $t("helpers.select") }}...
                    </option>
                    <option
                      v-for="(item, index) in operators"
                      :key="index"
                      :value="item._id">
                      {{ item.name }}
                    </option>
                  </select>
                </label>
              </div>
              <div class="col-sm-12 col-md-4" v-if="filterData.operator">
                <label class="d-inline-flex align-items-center w-100">
                  WL:
                  <select
                    v-model="filterData.wl"
                    name="wl"
                    class="form-select form-select-sm m-2 border-0 shadow-lg"
                    placeholder="Seleccione.."
                    @change="filtrar">
                    <option disabled selected>
                      {{ $t("helpers.select") }}...
                    </option>
                    <option
                      v-for="(item, index) in mapWls"
                      :key="index"
                      :value="item">
                      {{ item }}
                    </option>
                  </select>
                </label>
              </div>
              <div class="col-sm-12 col-md-4" v-if="filterData.client">
                <label class="d-inline-flex align-items-center w-100">
                  {{ $t("filter.currency") }}:
                  <select
                    v-model="filterData.currency"
                    name="client"
                    class="form-select form-select-sm m-2 border-0 shadow-lg"
                    placeholder="Seleccione..">
                    <option disabled selected>
                      {{ $t("helpers.select") }}...
                    </option>
                    <option value="*">ALL</option>
                    <option
                      v-for="(item, index) in currencies"
                      :key="index"
                      :value="item._id">
                      {{ item.name }}
                    </option>
                  </select>
                </label>
              </div>
            </div>
            <div class="col-sm-12 col-md-4">
              <label class="d-inline-flex align-items-center w-100 m-1">
                <vs-tooltip>
                  <span>{{ $t("filter.player") }} Admin:</span>
                  <vs-switch v-model="playerAdmin" class="m-1" />
                  <template #tooltip
                    >Habilitar para filtrar con jugadores de test</template
                  >
                </vs-tooltip>
              </label>
            </div>
            <div class="d-flex align-items-center mt-2">
              <vs-select
                placeholder="API VERSION"
                v-model="filterData.apiVersion">
                <vs-option label="V1" value="V1">V1</vs-option>
                <vs-option label="V2" value="V2">V2</vs-option>
              </vs-select>
              <vs-button :disabled="disableFilter">
                <downloadexcel
                  :fetch="exportData"
                  :fields="json_fields"
                  :name="fileName">
                  {{ $t("filter.downloadExcel") }}
                </downloadexcel>
              </vs-button>
              <DateRange @handleRadio="handleRadio" />
              <vs-button
                type="button"
                @click="filtrar()"
                :disabled="disableFilter">
                {{ $t("filter.filter") }}</vs-button
              >
              <vs-button
                type="button"
                success
                @click="cancelFilter()"
                :disabled="!Object.entries(filterData).length">
                {{ $t("filter.cancel") }}
              </vs-button>
            </div>

            <div class="text-center" v-if="fetching">
              <b-spinner type="grow" label="Spinning"></b-spinner>
              {{ $t("helpers.loading") }}...
            </div>

            <div class="row mt-2">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    {{ $t("filter.show") }}&nbsp;
                    <vs-select
                      v-model="perPage"
                      color="dark"
                      class="shadow-lg"
                      @change="filtrar">
                      <vs-option
                        v-for="(op, index) in pageOptions"
                        :key="index"
                        :label="op"
                        :value="op === 'ALL' ? 0 : op"
                        >{{ op }}</vs-option
                      >
                    </vs-select>
                    &nbsp;{{ $t("filter.entries") }}
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end">
                  <label class="d-inline-flex align-items-center m-2">
                    {{ $t("filter.search") }}:
                    <vs-input
                      v-model="filter"
                      type="search"
                      primary
                      class="shadow-lg"></vs-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <b-overlay :show="loading" rounded="lg">
              <div class="table-responsive mb-0">
                <b-table
                  :items="tableData"
                  :fields="fields"
                  responsive="sm"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  foot-clone>
                  <template #cell(createdAt)="data">
                    {{
                      moment
                        .utc(data.item.createdAt)
                        .format("DD-MM-YYYY HH:mm:ss")
                    }}
                  </template>
                  <template #cell(bet)="data">
                    <div>
                      <a
                        style="cursor: pointer"
                        title="Ver detalles"
                        variant="outline-primary"
                        @click="openDetails()"
                        >{{ data.item.bet }}</a
                      >
                    </div>
                  </template>
                  <template #cell(USDBETS)="data">
                    {{
                      data.item.currency &&
                      exchangeTransform(
                        data.item.totalBet,
                        data.item.currency.usdExchange
                      )
                    }}
                  </template>
                  <template #cell(USDWIN)="data">
                    {{
                      data.item.currency &&
                      exchangeTransform(
                        data.item.totalWin,
                        data.item.currency.usdExchange
                      )
                    }}
                  </template>
                  <template #cell(USDPROFIT)="data">
                    {{
                      data.item.currency &&
                      exchangeTransform(
                        data.item.totalProfit,
                        data.item.currency.usdExchange
                      )
                    }}
                  </template>
                  <template #foot(name)>
                    <span>TOTAL</span>
                  </template>
                  <template #foot(currency)>{{ "-" }} </template>
                  <template #foot(rounds)>
                    <span>{{ total && formatNumber(total.total_rounds) }}</span>
                  </template>
                  <template #foot(bets)>
                    <span>{{
                      total && formatNumber(total.total_betsAmount)
                    }}</span>
                  </template>
                  <template #foot(wins)>
                    <span>{{ total && formatNumber(total.total_wins) }}</span>
                  </template>
                  <template #foot(profit)>
                    <span>{{ total && formatNumber(total.total_profit) }}</span>
                  </template>
                </b-table>
                <!-- <d  -->
              </div>
            </b-overlay>
            <!-- <p
              class="text-center my-2"
              v-if="searchActive && !tableData.length && !loading">
              {{ $t("tableReports.noResults") }}
            </p> -->
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <button
                      class="btn btn-secondary mx-2 uil-angle-left"
                      @click="beforePage"
                      :disabled="!skip"></button>
                    <button
                      class="btn btn-primary mx-2 uil-angle-right"
                      @click="nextPage"
                      :disabled="tableData.length < limit"></button>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { Swal } from "sweetalert2";
import downloadexcel from "vue-json-excel";
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header-simple";
import DateRange from "../components/DateRange.vue";
import { breadCrumbData } from "../../../../helpers/breadcrumbs/breadCrumbs";
import { onlyAdmins } from "../../../../router/guards/roles";

export default {
  components: {
    Layout,
    PageHeader,
    downloadexcel,
    DateRange,
  },
  page: {
    title: "GGR BY WL",
  },
  computed: {
    disableFilter() {
      const requiredKeys = ["startDate", "endDate", "gameType"];

      if (
        Object.keys(this.filterData).filter((a) => requiredKeys.includes(a))
          .length === requiredKeys.length
      )
        return false;
      return true;
    },
    ...mapGetters({
      gameTypes: "reports/getGamesTypes",
      clients: "clients/getClients",
      operators: "operators/getOperators",
      currencies: "currencies/getCurrencies",
      mapWls: "reports/getWls",
      mapGgr: "reports/getGgr",
      formatNumber: "reports/formatNumbers",
    }),
  },
  mounted() {
    this.getUserRole();
  },
  data() {
    return {
      title: "GGR BY WL",
      items: breadCrumbData.ggrByCasino,
      filterData: {
        apiVersion: "V2",
      },
      readAllRoles: onlyAdmins,
      userRole: "",
      userData: "",
      playerAdmin: false,
      fetching: false,
      loading: false,
      searchActive: false,
      tableData: [],
      total: null,
      limit: null,
      skip: 0,
      perPage: 10,
      pageOptions: [10, 25, 50, 100, "ALL"],
      currentPage: 1,
      filter: null,
      filterOn: ["name"],
      fileName: "GGR-by-wl.xls",
      json_fields: {
        ["WL"]: "name",
        ["# Bets"]: "rounds",
        [this.$t("tableReports.totalBet")]: "bets",
        [this.$t("tableReports.totalWin")]: "wins",
        [this.$t("tableReports.totalProfit")]: "profit",
      },
      fields: [
        {
          key: "name",
          sortable: true,
          label: "WL".toUpperCase(),
          class: "space-nowrap",
        },
        {
          key: "operator",
          sortable: true,
          label: "operator".toUpperCase(),
          class: "space-nowrap",
        },
        {
          key: "currency",
          sortable: true,
          label: "currency".toUpperCase(),
          class: "space-nowrap",
        },
        {
          key: "rounds",
          sortable: true,
          label: this.$t("tableReports.countBets").toUpperCase(),
          class: "space-nowrap text-end",
          formatter: (value) => {
            return this.formatNumber(value);
          },
        },
        {
          key: "bets",
          sortable: true,
          label: this.$t("tableReports.totalBet"),
          class: "space-nowrap text-end",
          formatter: (value) => {
            return this.formatNumber(value);
          },
        },
        {
          key: "wins",
          sortable: true,
          label: this.$t("tableReports.totalWin"),
          class: "space-nowrap text-end",
          formatter: (value) => {
            return this.formatNumber(value);
          },
        },
        {
          key: "profit",
          sortable: true,
          label: this.$t("tableReports.profit").toUpperCase(),
          class: "space-nowrap text-end",
          formatter: (value) => {
            return this.formatNumber(value);
          },
        },
      ],
    };
  },
  methods: {
    checkAdmins() {
      return this.readAllRoles.includes(this.userRole);
    },
    getUserRole() {
      this.userData = this.$store.getters["user/getUserData"];
      this.userRole = this.userData.role.name;

      if (this.userRole === "CLIENT") {
        this.filterData.client = this.userData.client;
        this.getOperatorClient();
        // this.getRoulettesClient();
      } else if (this.userRole === "OPERATOR") {
        this.filterData.operator = this.userData.operator;
        // this.getRoulettesOperator()
      }
      this.fetchClients();
      this.fetchCurrencies({ role: this.userRole, user: this.userData });
    },
    async exportData() {
      if (!this.filterData.startDate || !this.filterData.endDate)
        return Swal.fire(
          "Es necesario seleccionar un rango de fecha",
          "",
          "info"
        );

      this.fetching = true;

      const { data } = await this.$httpWheel.get(
        `/reports/${this.filterData.apiVersion}/ggr-by-wls`,
        {
          params: {
            ...this.filterData,
            limit: this.perPage,
            page: this.currentPage,
          },
        }
      );

      if (data.ok) {
        this.fetching = false;

        const formatted = data.data.map((i) => {
          return {
            ...i,
            bets: this.formatNumber(i.bets),
            wins: this.formatNumber(i.wins),
            profit: this.formatNumber(i.profit),
          };
        });

        return formatted;
      } else {
        Swal.fire({
          position: "top-end",
          icon: "danger",
          title: "Advertencia, no se encuentran datos asociados",
          showConfirmButton: false,
          timer: 2000,
        });
      }

      this.fetching = false;
    },
    async handleRadio(value) {
      this.picked = value;
      switch (this.picked) {
        case this.$t("helpers.onPage"):
          break;
        case this.$t("helpers.today"): {
          const initialDate = new Date().toJSON().split("T")[0];
          this.filterData = {
            ...this.filterData,
            startDate: initialDate,
            endDate: initialDate,
          };
          break;
        }

        case this.$t("helpers.month"): {
          let from = 1;
          let to = this.daysInMonth(
            new Date().getMonth(),
            new Date().getFullYear()
          );
          const initialDate = new Date().toJSON().split("T")[0].split("-");
          let startDate = [initialDate[0], initialDate[1], from].join("-");
          let endDate = [initialDate[0], initialDate[1], to].join("-");
          this.filterData = { ...this.filterData, startDate, endDate };
          break;
        }
        default:
          break;
      }
      return true;
    },
    async getOperatorClient(filter) {
      const clientId = filter ? this.filterData.client : this.userData.client;

      try {
        const options = { params: { client: clientId } };
        await this.fetchOperators({
          options,
          optionsAdminApi: { clientId },
        });
        this.filtrar();
      } catch (err) {
        console.log("Error al obtener operadores del cliente", err);
      }
    },
    daysInMonth(month, year) {
      return new Date(year, month + 1, 0).getDate();
    },
    cancelFilter() {
      this.searchActive = false;

      this.filterData = {
        apiVersion: "V2",
      };
      this.getUserRole();
    },
    async filtrar() {
      this.searchActive = true;
      this.loading = true;

      const filterData = {
        ...this.filterData,
        limit: this.perPage,
        page: this.currentPage,
      };

      await this.fetchGgr({ filterData });

      const { data, total } = this.mapGgr;
      this.tableData = data;
      this.total = total;

      this.loading = false;
    },
    nextPage() {
      this.skip += this.perPage;
      this.currentPage++;
      this.filtrar();
    },
    beforePage() {
      this.skip -= this.perPage;
      this.currentPage--;
      this.filtrar();
    },
    ...mapActions({
      fetchClients: "clients/fetchClients",
      fetchOperators: "operators/fetchOperators",
      fetchCurrencies: "currencies/fetchCurrencies",
      fetchWls: "reports/fetchWls",
      fetchGgr: "reports/fetchGgrByWl",
    }),
  },
  watch: {
    playerAdmin(data) {
      this.filterData.isAdmin = data ? data : "";
    },
    "filterData.startHour": function (hour) {
      if (!hour) return (this.filterData.startDateHour = "");
      this.filterData.startDateHour = `${this.filterData.startDate} ${hour}`;
    },
    "filterData.endHour": function (hour) {
      if (!hour) return (this.filterData.endDateHour = "");
      this.filterData.endDateHour = `${this.filterData.endDate} ${hour}`;
    },
  },
  middleware: "authentication",
};
</script>

<style lang="scss" scoped></style>
